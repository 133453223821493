<template>
  <footer
    class="relative isolate mt-12 w-full overflow-hidden border-t-4 border-yellow-400 bg-black sm:mt-16"
  >
    <div class="absolute inset-0 justify-center">
      <div class="relative isolate">
        <div
          class="absolute inset-0 z-0 h-full w-full animate-pulse opacity-20"
          style="animation-duration: 5s"
        >
          <BrandTopography />
        </div>
      </div>
      <div
        class="relative h-full w-full bg-gradient-to-b from-transparent via-neutral-950/50 to-neutral-950"
      />
    </div>
    <div
      class="relative mx-auto grid w-full max-w-[var(--breakpoint-2xl)] grid-cols-2 gap-8 px-6 py-8 sm:px-8 lg:grid-cols-6 lg:items-center"
    >
      <div class="col-span-2">
        <NuxtLink to="/" class="shrink-0">
          <NuxtImg
            provider="storyblok"
            class="h-20 w-auto"
            height="80"
            width="92"
            :src="story.content.site_logo.filename"
            :alt="story.content.site_logo.alt"
            loading="lazy"
            placeholder
          />
        </NuxtLink>
        <p class="mt-4 max-w-md text-lg text-neutral-400">
          {{ story.content.site_description }}
        </p>
        <div class="mt-4 flex items-center space-x-2.5">
          <span class="-mt-1 text-sm font-bold text-neutral-400 uppercase"
            >General:</span
          >
          <NuxtLink
            v-posthog-capture="'youtube_clicked'"
            title="YouTube"
            :to="story.content.youtube.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-youtube"
              class="size-5 text-[#FF0000] hover:opacity-80"
            />
          </NuxtLink>
          <NuxtLink
            v-posthog-capture="'linkedin_clicked'"
            title="LinkedIn"
            :to="story.content.linkedin.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-linkedin"
              class="size-5 text-[#0A66C2] hover:opacity-80"
            />
          </NuxtLink>
          <NuxtLink
            v-posthog-capture="'tiktok_clicked'"
            title="TikTok"
            :to="story.content.tiktok.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-tiktok"
              class="size-5 text-[#FFFFFF] hover:opacity-80"
            />
          </NuxtLink>
        </div>
        <div class="mt-2 flex items-center space-x-2.5">
          <span class="-mt-1 text-sm font-bold text-neutral-400 uppercase"
            >Adventures:</span
          >
          <NuxtLink
            v-posthog-capture="'facebook_clicked'"
            title="Facebook"
            :to="story.content.facebook_a.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-facebook"
              class="size-5 text-[#0866FF] hover:opacity-80"
            />
          </NuxtLink>
          <NuxtLink
            v-posthog-capture="'instagram_clicked'"
            title="Instagram"
            :to="story.content.instagram_a.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-instagram"
              class="size-5 text-[#E4405F] hover:opacity-80"
            />
          </NuxtLink>
        </div>
        <div class="mt-2 flex items-center space-x-2.5">
          <span class="-mt-1 text-sm font-bold text-neutral-400 uppercase"
            >Climbing:</span
          >
          <NuxtLink
            v-posthog-capture="'facebook_clicked'"
            title="Facebook"
            :to="story.content.facebook_c.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-facebook"
              class="size-5 text-[#0866FF] hover:opacity-80"
            />
          </NuxtLink>
          <NuxtLink
            v-posthog-capture="'instagram_clicked'"
            title="Instagram"
            :to="story.content.instagram_c.url"
            target="_blank"
          >
            <UIcon
              name="i-simple-icons-instagram"
              class="size-5 text-[#E4405F] hover:opacity-80"
            />
          </NuxtLink>
        </div>
      </div>
      <div class="col-span-2 grid grid-cols-2 gap-8">
        <div>
          <div class="px-2.5 text-sm font-extrabold text-yellow-400 uppercase">
            {{ story.content.column_one_heading }}
          </div>
          <UNavigationMenu
            orientation="vertical"
            :items="colOneMenu"
            variant="link"
            class="mt-1"
          />
        </div>
        <div>
          <div class="px-2.5 text-sm font-extrabold text-yellow-400 uppercase">
            {{ story.content.column_two_heading }}
          </div>
          <UNavigationMenu
            orientation="vertical"
            :items="colTwoMenu"
            variant="link"
            class="mt-1"
          />
        </div>
      </div>
      <div class="col-span-2 max-w-prose">
        <div class="-mt-1 text-base font-extrabold text-white">
          Subscribe to the Adventure Almanac
        </div>
        <p class="mt-0.5 max-w-prose text-neutral-400">
          Get our latest stories, competitions, events & offers, straight to
          your inbox.
        </p>
        <LazyMarketingFormNewsletter />
        <p class="mt-2 text-sm text-neutral-400">
          We care about your data. Read our
          <NuxtLink
            to="/"
            class="text-yellow-400 underline hover:text-yellow-500"
          >
            privacy policy </NuxtLink
          >.
        </p>
      </div>
    </div>
    <div class="relative w-full border-t border-neutral-900 bg-neutral-950">
      <div
        class="mx-auto w-full max-w-[var(--breakpoint-2xl)] px-6 py-4 text-sm text-neutral-400 sm:px-8"
      >
        <div class="flex items-center justify-center text-center">
          © 2012-{{ new Date().getFullYear() }} Live For Today LTD. All rights
          reserved. Company number: 07555704.
          <br class="md:hidden" />
          Made with ♥ in Yorkshire, 54° N, -1.5° W.
        </div>
        <div class="flex items-center justify-center space-x-1">
          <NuxtLink
            to="https://status.live-for-today.com/"
            class="flex items-center text-yellow-400 underline hover:text-yellow-500"
          >
            <div class="relative mr-1">
              <div
                class="absolute h-1.5 w-1.5 animate-ping rounded-full bg-yellow-400"
              />
              <div class="relative h-1.5 w-1.5 rounded-full bg-yellow-400" />
            </div>
            Status
          </NuxtLink>
          <span>·</span>
          <div>
            Version:
            <NuxtLink
              to="/changelog"
              class="text-yellow-400 underline hover:text-yellow-500"
              >v{{ config.public.VERSION }}</NuxtLink
            >
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const config = useRuntimeConfig();
const isPreview =
  useRuntimeConfig().public.NUXT_PUBLIC_NODE_ENV !== 'production';

const story = await useAsyncStoryblok(
  'config',
  {
    version: isPreview ? 'draft' : 'published',
    // resolve_relations: 'overview.featured_story',
  }
  // { resolveRelations: 'overview.featured_story' }
);

// Navigation Menus
const colOneMenu = ref([]);
const colTwoMenu = ref([]);

// Format Navigation menu Items
function processMenuData(menuData) {
  return menuData.map((item) => {
    const newItem = {};

    newItem.label = item.label;
    newItem.icon = item.icon;

    if (item.to) {
      let url = '';
      if (item.to.linktype === 'story') {
        url = `/${item.to.cached_url}`;
      } else {
        url = item.to.url;
      }
      // Remove trailing slashes
      url = url.replace(/\/+$/, '');
      newItem.to = url;
    }

    if (item.description) {
      newItem.description = item.description;
    }

    if (item.children && item.children.length > 0) {
      newItem.children = processMenuData(item.children);
    }

    return newItem;
  });
}

if (story.value && story.value.content) {
  colOneMenu.value = processMenuData(story.value.content.column_one_links);
  colTwoMenu.value = processMenuData(story.value.content.column_two_links);
}
</script>
